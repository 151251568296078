.bodyAddtranspage {
  padding-left: 20px;
}
.addtransactionpage {
  font-size: medium;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(236, 236, 236, 0.9);
  padding: 25px;
  border-radius: 5px;
  z-index: 1000;
}
.addtransadiv {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  top: 20%;
  background-color: white;
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.hrAddtranspage {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}
.headerAddtranspage {
  background-color: white;
  text-align: center;
}

.leftarrowIcon {
  float: left;
}
.closeIcon {
  float: right;
  cursor: pointer;
}

.enteramountBox ::placeholder {
  color: #242736;
  font-weight: 800;
  font-size: 30px;
  padding: 2px;
}
.enteramountBox {
  margin: 40px 0 10px 0;
}
.enteramountBox span {
  color: #242736;
  font-weight: 800;
  font-size: 30px;
  padding: 2px;
}
.enteramountBox input {
  color: #242736;
  font-weight: 800;
  font-size: 30px;
  border: none;
  width: 200px;
}
.enteramountBox input:focus {
  outline: none;
}

/* styles added for radio button*/
.toggleExpensetype {
  border: solid 1px #79747e;
  border-radius: 7px;
  display: inline-block;
  padding: 0;
  margin-bottom: 5px;
}
.et {
  visibility: hidden;
  position: absolute;
}
.expensetypes {
  margin: 0;
}
.expensetypes span {
  display: inline-block;
  color: #49454f80;
  padding: 5px 20px;
  cursor: pointer;
}
.expensetypes span:hover {
  color: black;
}
.expensetypes input:checked + span {
  color: black;
  background-color: #ebf9eb;

  border: solid 1px black;
  border-radius: 5px;
}
/* */
.addnotediv {
  margin: 5px 0;
}
.categorydiv {
  margin: 5px 0;
}

.pmDiv {
  margin: 5px 0;
}

.pm {
  visibility: hidden;
  position: absolute;
}
.paymentmodes {
  span {
    color: black;
    padding: 2px 5px;
    margin: 5px;
    border: solid 1px #79747e;
    border-radius: 5px;
    cursor: pointer;
  }
  input:checked + span {
    color: #ef5da8;
    font-weight: bold;
    padding: 2px 2px;
    border: solid 2px #ef5da8;
    border-radius: 5px;
  }
}

.savebutton {
  margin: 5px;
  float: left;
  margin-top: 15px;
}
