.root {
  .li {
    font-size: 18px;
    list-style: none;
    font-weight: bolder;
    margin: 30px 10px;
    
    .totalspan {
      
      float: right;
    }
    img {
      vertical-align: text-bottom;
      margin: 0 5px 0 0;
    }
    
    
    
  }
  .deleteIcon{
    vertical-align: -30%;
    
    margin: 5px 15px;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    
      
    }
    .expand{
      
      list-style: disc;
      justify-content: space-between;
    }
    .deletespan{
      
      justify-content: right;
      

    }
    .expandeddiv{
      
      display: flex;
      justify-content: space-between;
      
    }
    .namediv{
      justify-content: right;
    }
  
}
