.expenses {
  position: relative;
  padding: 25px;
  display: block;

  .yourexp {
    font-size: 20px;
    font-weight: 600;
    padding-top: 20px;
    span {
      margin: 15px;
      color: grey;
    }
  }
}

.header {
  div {
    text-align: center;
  }
  h1 {
    font-size: 40px;
    margin: 5px auto;
  }
  h4 {
    color: grey;
  }

  .formonth {
    color: grey;
    cursor: pointer;
    img {
      vertical-align: middle;
    }
  }
}
.addbutton {
  position: fixed;
  margin: 50px;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
