.root {
  /* display: none; */
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(236, 236, 236, 0.9);
  z-index: 999;

  h2 {
    padding-left: 20px;
  }

  .selector {
    padding: 20px;
    background-color: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    margin-top: 100%;
    bottom: 100%;
  }
  .yearMonthBox {
    display: flex;
    align-items: stretch;
    margin: 5px;
    div {
      margin: 5px;
      font-size: large;
      font-weight: 500;
      color: #aeaeb3;
    }
    .active {
      color: #242736;
      font-weight: bold;
    }
  }
  .months {
    display: grid;
    grid: auto/ auto auto auto;
    text-align: center;
    & div {
      padding: 10px;
      margin: 5px;
      &:hover {
        background-color: rgba(166, 231, 166, 0.23);
        border-radius: 5px;
      }
    }
  }
}
